<template>
  <div>
    <Header />
    <div class="bg-mid-grey mt-5">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-5 pb-5">
            <h1 class="page-header">SHARE OF RANIBIZUMAB - <i>SWITCH PATIENTS</i></h1>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-light-grey">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div v-if="timeframe === 3" class="cell small-12 medium-3 medium-offset-3 pt-5">
            <h5 class="text-center">Current situation</h5>
          </div>
          <div v-else class="cell small-12 medium-3 medium-offset-2 pt-5">
            <h5 class="text-center">Current situation</h5>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-3 medium-offset-2 pt-5">
            <h5 class="text-center">Future situation</h5>
          </div>
          <div v-else class="cell small-12 medium-3 pt-5">
            <h5 class="text-center">Future situation</h5>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-1 medium-offset-3">
            <p class="mb-0 text-center">Year 1</p>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1">
            <p class="mb-0 text-center">Year 2</p>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1">
            <p class="mb-0 text-center">Year 3</p>
          </div>
          <div class="cell small-12 medium-1 medium-offset-2">
            <p class="mb-0 text-center">Year 1</p>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1">
            <p class="mb-0 text-center">Year 2</p>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1">
            <p class="mb-0 text-center">Year 3</p>
          </div>
        </div>
        <div v-for="(drug, index) in drugs" :key="index" class="grid-x grid-padding-x">
          <div class="cell small-12 medium-2 pt-5">
            <button class="button expanded blue-active">
              <i v-if="drug === 'byoviz'" class="plain">Byooviz</i>
              <i v-else class="plain">{{ drug }}</i>
            </button>
          </div>
          <div class="cell small-12 medium-1 medium-offset-1 pt-5">
            <number v-number="percentage" v-if="drug === 'byoviz'" type="text" class="empty text-center" v-model="byovizYearOneShareSwitch"></number>
            <number v-number="percentage" v-if="drug === 'lucentis'" type="text" class="empty text-center" v-model="lucentisYearOneShareSwitch"></number>
            <number v-number="percentage" v-if="drug === 'biosimilars 2'" type="text" class="empty text-center" v-model="biosimilarYearOneShareSwitch"></number>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1 pt-5">
            <number v-number="percentage" v-if="drug === 'byoviz'" type="text" class="empty text-center" v-model="byovizYearTwoShareSwitch"></number>
            <number v-number="percentage" v-if="drug === 'lucentis'" type="text" class="empty text-center" v-model="lucentisYearTwoShareSwitch"></number>
            <number v-number="percentage" v-if="drug === 'biosimilars 2'" type="text" class="empty text-center" v-model="biosimilarYearTwoShareSwitch"></number>
          </div>
          <div  v-if="timeframe === 3" class="cell small-12 medium-1 pt-5">
            <number v-number="percentage" v-if="drug === 'byoviz'" type="text" class="empty text-center" v-model="byovizYearThreeShareSwitch"></number>
            <number v-number="percentage" v-if="drug === 'lucentis'" type="text" class="empty text-center" v-model="lucentisYearThreeShareSwitch"></number>
            <number v-number="percentage" v-if="drug === 'biosimilars 2'" type="text" class="empty text-center" v-model="biosimilarYearThreeShareSwitch"></number>
          </div>
          <div class="cell small-12 medium-1 medium-offset-2 pt-5">
            <number v-number="percentage" v-if="drug === 'byoviz'" type="text" class="empty text-center" v-model="byovizYearOneFutureShareSwitch"></number>
            <number v-number="percentage" v-if="drug === 'lucentis'" type="text" class="empty text-center" v-model="lucentisYearOneFutureShareSwitch"></number>
            <number v-number="percentage" v-if="drug === 'biosimilars 2'" type="text" class="empty text-center" v-model="biosimilarYearOneFutureShareSwitch"></number>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1 pt-5">
            <number v-number="percentage" v-if="drug === 'byoviz'" type="text" class="empty text-center" v-model="byovizYearTwoFutureShareSwitch"></number>
            <number v-number="percentage" v-if="drug === 'lucentis'" type="text" class="empty text-center" v-model="lucentisYearTwoFutureShareSwitch"></number>
            <number v-number="percentage" v-if="drug === 'biosimilars 2'" type="text" class="empty text-center" v-model="biosimilarYearTwoFutureShareSwitch"></number>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1 pt-5">
            <number v-number="percentage" v-if="drug === 'byoviz'" type="text" class="empty text-center" v-model="byovizYearThreeFutureShareSwitch"></number>
            <number v-number="percentage" v-if="drug === 'lucentis'" type="text" class="empty text-center" v-model="lucentisYearThreeFutureShareSwitch"></number>
            <number v-number="percentage" v-if="drug === 'biosimilars 2'" type="text" class="empty text-center" v-model="biosimilarYearThreeFutureShareSwitch"></number>
          </div>
          <div class="cell small-12">
            <hr class="wide orange-line mt mb" />
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-2 pt-5">
            <button class="button expanded blue-active">Total (100%)</button>
          </div>
          <div class="cell small-12 medium-1 medium-offset-1 pt-5">
            <number v-number="percentage" disabled type="text" class="fixed text-center" v-model="yearOneTotalShareSwitch"></number>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1 pt-5">
            <number v-number="percentage" disabled type="text" class="fixed text-center" v-model="yearTwoTotalShareSwitch"></number>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1 pt-5">
            <number v-number="percentage" disabled type="text" class="fixed text-center" v-model="yearThreeTotalShareSwitch"></number>
          </div>
          <div class="cell small-12 medium-1 medium-offset-2 pt-5">
            <number v-number="percentage" disabled type="text" class="fixed text-center" v-model="yearOneTotalShareFutureSwitch"></number>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1 pt-5">
            <number v-number="percentage" disabled type="text" class="fixed text-center" v-model="yearTwoTotalShareFutureSwitch"></number>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1 pt-5">
            <number v-number="percentage" disabled type="text" class="fixed text-center" v-model="yearThreeTotalShareFutureSwitch"></number>
          </div>
        </div>
        <div class="grid-x grid-padding-x pt-20">
          <div class="cell small-12 medium-4">
            <h5>Select speed of implementation</h5>
          </div>
        </div>
        <div class="grid-x grid-padding-x pb-5">
          <div class="cell small-12 medium-2">
            <button v-if="speedOfImplementation !== 3" @click="setImplementationSpeed(3)" class="button expanded tall blue-ghost">3 Months</button>
            <button v-if="speedOfImplementation === 3" class="button expanded tall blue-active">3 Months</button>
          </div>
          <div class="cell small-12 medium-2">
            <button v-if="speedOfImplementation !== 6" @click="setImplementationSpeed(6)" class="button expanded tall blue-ghost">6 Months</button>
            <button v-if="speedOfImplementation === 6" class="button expanded tall blue-active">6 Months</button>
          </div>
          <div class="cell small-12 medium-2">
            <button v-if="speedOfImplementation !== 9" @click="setImplementationSpeed(9)" class="button expanded tall blue-ghost">9 Months</button>
            <button v-if="speedOfImplementation === 9" class="button expanded tall blue-active">9 Months</button>
          </div>
          <div class="cell small-12 medium-2 medium-offset-4 pt-10 text-right">
            <div v-if="timeframe === 3">
              <div v-if="yearOneTotalShareSwitch === 100 && yearOneTotalShareFutureSwitch === 100 && yearTwoTotalShareSwitch === 100 && yearTwoTotalShareFutureSwitch === 100 && yearThreeTotalShareSwitch === 100 && yearThreeTotalShareFutureSwitch === 100">
                <router-link to="/summary">
                  <button class="button">Next</button>
                </router-link>
              </div>
              <div v-else>
                <button disabled class="button disabled">Next</button>
              </div>
            </div>
            <div v-else>
              <div v-if="yearOneTotalShareSwitch === 100 && yearOneTotalShareFutureSwitch === 100 && speedOfImplementation !== 0">
                <router-link to="/summary">
                  <button class="button">Next</button>
                </router-link>
              </div>
              <div v-else>
                <button disabled class="button disabled">Next</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
  name: 'Share',
  components: {
    Header,
  },
  data() {
    return {
      percentage: {
        masked: true,
        suffix: '%',
      },
    };
  },
  computed: {
    hash() {
      return this.$store.state.hash;
    },
    drugs() {
      return this.$store.state.drugs;
    },
    timeframe() {
      return this.$store.state.timeframe;
    },
    speedOfImplementation() {
      return this.$store.state.speedOfImplementation;
    },
    byovizYearOneShareSwitch: {
      get() { return this.$store.state.byovizYearOneShareSwitch; },
      set(value) { this.$store.commit('updatebyovizYearOneShareSwitch', value); },
    },
    byovizYearTwoShareSwitch: {
      get() { return this.$store.state.byovizYearTwoShareSwitch; },
      set(value) { this.$store.commit('updatebyovizYearTwoShareSwitch', value); },
    },
    byovizYearThreeShareSwitch: {
      get() { return this.$store.state.byovizYearThreeShareSwitch; },
      set(value) { this.$store.commit('updatebyovizYearThreeShareSwitch', value); },
    },
    lucentisYearOneShareSwitch: {
      get() { return this.$store.state.lucentisYearOneShareSwitch; },
      set(value) { this.$store.commit('updatelucentisYearOneShareSwitch', value); },
    },
    lucentisYearTwoShareSwitch: {
      get() { return this.$store.state.lucentisYearTwoShareSwitch; },
      set(value) { this.$store.commit('updatelucentisYearTwoShareSwitch', value); },
    },
    lucentisYearThreeShareSwitch: {
      get() { return this.$store.state.lucentisYearThreeShareSwitch; },
      set(value) { this.$store.commit('updatelucentisYearThreeShareSwitch', value); },
    },
    biosimilarYearOneShareSwitch: {
      get() { return this.$store.state.biosimilarYearOneShareSwitch; },
      set(value) { this.$store.commit('updatebiosimilarYearOneShareSwitch', value); },
    },
    biosimilarYearTwoShareSwitch: {
      get() { return this.$store.state.biosimilarYearTwoShareSwitch; },
      set(value) { this.$store.commit('updatebiosimilarYearTwoShareSwitch', value); },
    },
    biosimilarYearThreeShareSwitch: {
      get() { return this.$store.state.biosimilarYearThreeShareSwitch; },
      set(value) { this.$store.commit('updatebiosimilarYearThreeShareSwitch', value); },
    },
    byovizYearOneFutureShareSwitch: {
      get() { return this.$store.state.byovizYearOneFutureShareSwitch; },
      set(value) { this.$store.commit('updatebyovizYearOneFutureShareSwitch', value); },
    },
    byovizYearTwoFutureShareSwitch: {
      get() { return this.$store.state.byovizYearTwoFutureShareSwitch; },
      set(value) { this.$store.commit('updatebyovizYearTwoFutureShareSwitch', value); },
    },
    byovizYearThreeFutureShareSwitch: {
      get() { return this.$store.state.byovizYearThreeFutureShareSwitch; },
      set(value) { this.$store.commit('updatebyovizYearThreeFutureShareSwitch', value); },
    },
    lucentisYearOneFutureShareSwitch: {
      get() { return this.$store.state.lucentisYearOneFutureShareSwitch; },
      set(value) { this.$store.commit('updatelucentisYearOneFutureShareSwitch', value); },
    },
    lucentisYearTwoFutureShareSwitch: {
      get() { return this.$store.state.lucentisYearTwoFutureShareSwitch; },
      set(value) { this.$store.commit('updatelucentisYearTwoFutureShareSwitch', value); },
    },
    lucentisYearThreeFutureShareSwitch: {
      get() { return this.$store.state.lucentisYearThreeFutureShareSwitch; },
      set(value) { this.$store.commit('updatelucentisYearThreeFutureShareSwitch', value); },
    },
    biosimilarYearOneFutureShareSwitch: {
      get() { return this.$store.state.biosimilarYearOneFutureShareSwitch; },
      set(value) { this.$store.commit('updatebiosimilarYearOneFutureShareSwitch', value); },
    },
    biosimilarYearTwoFutureShareSwitch: {
      get() { return this.$store.state.biosimilarYearTwoFutureShareSwitch; },
      set(value) { this.$store.commit('updatebiosimilarYearTwoFutureShareSwitch', value); },
    },
    biosimilarYearThreeFutureShareSwitch: {
      get() { return this.$store.state.biosimilarYearThreeFutureShareSwitch; },
      set(value) { this.$store.commit('updatebiosimilarYearThreeFutureShareSwitch', value); },
    },
    yearOneTotalShareSwitch() { return (parseInt(this.byovizYearOneShareSwitch, 10) + parseInt(this.lucentisYearOneShareSwitch, 10) + parseInt(this.biosimilarYearOneShareSwitch, 10)); },
    yearTwoTotalShareSwitch() { return (parseInt(this.byovizYearTwoShareSwitch, 10) + parseInt(this.lucentisYearTwoShareSwitch, 10) + parseInt(this.biosimilarYearTwoShareSwitch, 10)); },
    yearThreeTotalShareSwitch() { return (parseInt(this.byovizYearThreeShareSwitch, 10) + parseInt(this.lucentisYearThreeShareSwitch, 10) + parseInt(this.biosimilarYearThreeShareSwitch, 10)); },
    yearOneTotalShareFutureSwitch() { return (parseInt(this.byovizYearOneFutureShareSwitch, 10) + parseInt(this.lucentisYearOneFutureShareSwitch, 10) + parseInt(this.biosimilarYearOneFutureShareSwitch, 10)); },
    yearTwoTotalShareFutureSwitch() { return (parseInt(this.byovizYearTwoFutureShareSwitch, 10) + parseInt(this.lucentisYearTwoFutureShareSwitch, 10) + parseInt(this.biosimilarYearTwoFutureShareSwitch, 10)); },
    yearThreeTotalShareFutureSwitch() { return (parseInt(this.byovizYearThreeFutureShareSwitch, 10) + parseInt(this.lucentisYearThreeFutureShareSwitch, 10) + parseInt(this.biosimilarYearThreeFutureShareSwitch, 10)); },
  },
  methods: {
    setImplementationSpeed(time) {
      this.$store.commit('setImplementationSpeed', time);
    },
    sendPage() {
      this.$store.commit('setPage', 'share');
    },
    checkHashExists() {
      if (this.hash.length === 0) {
        this.$router.push('/inactive-link');
      }
    },
  },
  mounted() {
    this.checkHashExists();
    this.sendPage();
  },
};
</script>
